<template>
  <div class="course__header">
    <div class="course__header-content">
      <button @click="btnHandler" :disabled="isSending" class="v-btn">{{ btnTitle }}</button>
      <!--            <icon height="40" width="40" icon-color="none">
                      <circle cx="20" cy="20" r="19" stroke="#E6EBF3" stroke-width="2"/>
                      <path d="M28 27C27.5 20 23 17 12 18.5001M12 18.5001L17.5 14M12 18.5001L17.5 22.5" stroke="#989CAE" stroke-width="2"/>
                  </icon>-->
      <router-link v-if="returnRoute" tag="button" :to="returnRoute"
                   class="v-btn v-btn--transparent course__close-btn">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1L1 17" stroke="#989CAE" stroke-width="2"/>
          <path d="M1 1L17 17" stroke="#989CAE" stroke-width="2"/>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseHeader',
  props: ['btnHandler', 'returnRoute', 'btnTitle', 'isSending'],
  components: {
    Icon : () => import('@/components/icon/Icon')
  },
  /*data () {
    return {
      isBtnDisabled: false
    }
  },
  watch: {
    isSending(newVal, oldVal) {
      this.isBtnDisabled = newVal;
      debugger
    }
  }*/
}
</script>

<style lang="scss">
@import "course";

.course {

  &__header {
    width: calc(100% - 300px);

    @media (max-width: 1000px) {
      width: 100%;
      padding: 20px 25px;
    }
    @media (max-width: 700px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__header-content {
    max-width: 1000px;

    @media (max-width: 1300px) {
      padding: 0 24px;
    }

    @media (max-width: 1000px) {
      padding: 0;
    }
  }

  &__close-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-right: 0;
    margin-left: auto;
  }
}
</style>
